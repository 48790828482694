<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text"> Upload {{ title }} </span>
      </v-card-title>
      <v-card-text class="pt-3 my-4">
        <v-file-input small-chips hide-details outlined label="Upload"></v-file-input>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
        <ac-button title="Submit" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'

export default {
  name: 'ViewVideoModal',
  components: {
    AcButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      quizExpand: null,
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  max-width: 600px;
}
</style>
